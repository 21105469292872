import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import SignupForm from './components/SignupForm';
import LoginForm from './components/LoginForm';
import LoggedIn from './components/LoggedIn';
import Perplexity from './components/Perplexity';
import PasswordResetRequestForm from './components/PasswordResetRequestForm';
import PasswordResetForm from './components/PasswordResetForm';
import theme from './theme';
import './App.css';
import Pulse from './components/Pulse';

// Configure axios to use token from localStorage if it exists
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [currentView, setCurrentView] = useState('login');
  const [resetToken, setResetToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/verify-token`, 
        { withCredentials: true }
      );
      
      if (response.data.isValid) {
        setIsLoggedIn(true);
        setUserEmail(response.data.user.email);
      } else {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
      }
    } catch (error) {
      console.error('Auth check failed:', error);
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      setIsLoggedIn(false);
      setUserEmail(null);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleForm = () => {
    setCurrentView(!currentView);
  };

  const handleLoginSuccess = (email) => {
    setIsLoggedIn(true);
    setUserEmail(email);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
    setIsLoggedIn(false);
    setUserEmail(null);
  };

  const handleBackToLogin = () => {
    setCurrentView('login');
    setResetToken(null);
    window.history.pushState({}, '', '/');
  };

  const renderCurrentView = () => {
    switch (currentView) {
      case 'login':
        return (
          <LoginForm
            onToggleForm={() => setCurrentView('signup')}
            onLoginSuccess={handleLoginSuccess}
            onForgotPassword={() => setCurrentView('resetRequest')}
          />
        );
      case 'signup':
        return (
          <SignupForm onToggleForm={() => setCurrentView('login')} />
        );
      case 'resetRequest':
        return (
          <PasswordResetRequestForm onBackToLogin={handleBackToLogin} />
        );
      case 'resetPassword':
        return (
          <PasswordResetForm
            token={resetToken}
            onBackToLogin={handleBackToLogin}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={
              isLoggedIn ? <Navigate to="/pulse" /> : <LoginForm 
                onToggleForm={() => setCurrentView('signup')}
                onLoginSuccess={handleLoginSuccess}
                onForgotPassword={() => setCurrentView('resetRequest')}
              />
            } />
            
            <Route path="/pulse" element={
              isLoggedIn ? (
                <Pulse onLogout={handleLogout} userEmail={userEmail} />
              ) : (
                <Navigate to="/login" />
              )
            } />

            <Route path="/*" element={<Perplexity />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
