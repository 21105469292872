import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
  TextField,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import Footer from './Footer';

const Pulse = ({ onLogout, userEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pulseTopics, setPulseTopics] = useState([]);
  const [newTopic, setNewTopic] = useState('');
  const [error, setError] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Fetch pulse topics on component mount
  useEffect(() => {
    fetchPulseTopics();
  }, []);

  const fetchPulseTopics = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { withCredentials: true }
      );
      setPulseTopics(response.data);
    } catch (error) {
      console.error('Error fetching pulse topics:', error);
      setError('Failed to fetch pulse topics');
    }
  };

  const handleCreateTopic = async (e) => {
    e.preventDefault();
    if (!newTopic.trim()) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics`,
        { topic: newTopic },
        { withCredentials: true }
      );
      setPulseTopics([response.data, ...pulseTopics]);
      setNewTopic('');
    } catch (error) {
      console.error('Error creating pulse topic:', error);
      setError('Failed to create pulse topic');
    }
  };

  const handleToggleTopic = async (id, currentEnabled) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/pulse-topics/${id}/toggle`,
        {},
        { withCredentials: true }
      );
      setPulseTopics(pulseTopics.map(topic => 
        topic.id === id ? response.data : topic
      ));
    } catch (error) {
      console.error('Error toggling pulse topic:', error);
      setError('Failed to toggle pulse topic');
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        {},
        { withCredentials: true }
      );
      onLogout();
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isAdmin = ['test@test.com', 'mail@andrewjm.co'].includes(userEmail);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Container 
        maxWidth="md" 
        sx={{
          px: isMobile ? 2 : 3,
          py: isMobile ? 2 : 4,
          flex: 1,
        }}
      >
        <Box
          component="header"
          sx={{
            position: 'sticky',
            top: 0,
            bgcolor: 'background.paper',
            zIndex: 1,
            pb: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end', // Changed to pull logout button to the right
            gap: 2,
          }}
        >
          <Typography 
            component="h1" 
            variant={isMobile ? "h5" : "h4"} 
            sx={{ 
              fontWeight: 600,
              minWidth: 'fit-content',
              mr: 2,
            }}
          >
            dist*ll
          </Typography>

          <Button
            onClick={handleLogout}
            variant="text"
            disabled={isLoading}
            sx={{
              py: 1,
              px: 2,
              color: 'black', // Changed to black text
              border: '1px solid transparent',
              '&:hover': {
                border: '1px solid black', // Changed to black border on hover
                bgcolor: 'transparent',
              },
              minWidth: 'auto',
              ml: 1,
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Logout'}
          </Button>
        </Box>

        <Card 
          sx={{ 
            mb: 3,
            mt: 3,
            backgroundColor: '#f8f8f8',
            border: '1px solid #eaeaea',
            boxShadow: 'none',
          }}
        >
          <CardContent sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            py: 1,
            '&:last-child': { pb: 1 }, // Override MUI's default padding
          }}>
            <EmailIcon sx={{ color: '#666' }} />
            <Typography variant="body1" color="text.secondary">
              Sending daily pulses to <strong>{userEmail}</strong>
            </Typography>
          </CardContent>
        </Card>

        <Box sx={{mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2, textAlign: 'left' }}>
            Add a pulse
          </Typography>
          <Box component="form" onSubmit={handleCreateTopic} sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Enter a topic to track..."
              size="small"
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: 'black',
                '&:hover': { bgcolor: '#333' }
              }}
            >
              Add
            </Button>
          </Box>
        </Box>

        <Typography variant="h6" sx={{ mb: 2, textAlign: 'left' }}>
          Daily pulses
        </Typography>

        <List>
          {pulseTopics.map((topic) => (
            <ListItem
              key={topic.id}
              sx={{
                border: '1px solid #eaeaea',
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemText primary={topic.topic} />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={topic.enabled}
                  onChange={() => handleToggleTopic(topic.id, topic.enabled)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Container>
      <Footer />
    </Box>
  );
};

export default Pulse; 